<template>
  <div id="app" class="h-screen">
    <router-view />
  </div>
</template>
<script>
import Vue from "vue";
import WhitelistingMixin from "../src/modules/WhitelistingMixin.js";
import {
  GET_USER_DATA,
  GET_USER_BUSINESS_DATA,
} from "../src/browser-db-config/localStorage";
export default {
  mixins: [WhitelistingMixin],
  data() {
    return {
      fulllname: "",
      email: "",
    };
  },
  mounted() {
    this.fullname =
      GET_USER_DATA().name !== undefined ? GET_USER_DATA().name : "";
    // this.email = GET_USER_BUSINESS_DATA().email;
    // console.log(this.email);
    Vue.setupUserForSentryTracking({ User: this.fullname });
  },
  errorCaptured: function (err) {
    Vue.sentryCaptureException(err);
  },
};
</script>
<style lang="scss">
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,301,701,300,501,401,901,400&display=swap");
#app {
  font-family: "Satoshi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 40px;
  height: 40px;
}
.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
