import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const actions = {
  GET_ALL_WAITLIST: async function ({ commit }) {
    const httpReq = {
      url: `/v2/savings/wait-list-all`,
      method: 'GET',
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);

    return httpRes;
  },
};

export default {
  actions,
};
