import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  general_terminals: {},
};

const getters = {
  GET_GENERAL_TERMINALS_NEW: (state) => state.general_terminals,
};

const mutations = {
  SET_GENERAL_TERMINALS_NEW_RES: (state, data) => {
    state.general_terminals = data;
  },
};

const actions = {
  FETCH_GENERAL_TERMINAL_REQUESTS_NEW: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `v1/stores/listterminalrequest`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_GENERAL_TERMINAL_REQUEST_NEW_CSV: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listterminalrequest?download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  DOWNLOAD_GENERAL_DATE_TERMINAL_REQUESTS_LOG_CSV: async function(
    context,
    { date }
  ) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listterminalrequest?download=csv&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_GENERAL_DATED_TERMINAL_REQUESTS: async function(context, { date }) {
    const httpReq = {
      url: `v1/stores/listterminalrequest?startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`,
      method: "GET",
      //   body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },

  SEARCH_GENERAL_TERMINAL_REQUESTS_NEW: async function(context, search) {
    const httpReq = {
      url: `v1/stores/listterminalrequest?businessName=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },
  FETCH_GENERAL_PAGINATE_TERMINAL_REQUESTS_NEW: async function(
    context,
    {
      page,
      date,
      dateFiltered,
      filterActive,
      filterBy,
      filterParams,
      searchActive,
      search,
    }
  ) {
    let urlHandler = dateFiltered
      ? `/v1/stores/listterminalrequest?page=${page}&startdatetime=${date.startdatetime}&enddatetime=${date.enddatetime}`
      : `/v1/stores/listterminalrequest?page=${page}`;
    urlHandler += filterActive ? `&${filterBy}=${filterParams}` : ``;
    urlHandler += searchActive ? `&businessName=${search}` : ``;
    const httpReq = {
      url: urlHandler, //`v1/stores/listterminalrequest?source=${source}&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_GENERAL_TERMINAL_REQUEST_NEW_CSV: async function(context) {
    const httpReq = {
      url: `v1/stores/listterminalrequest?download=csv`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },
  FETCH_GENERAL_FILTERED_TERMINAL_REQUESTS: async function(
    context,
    { filterBy, filterParams }
  ) {
    const httpReq = {
      url: `v1/stores/listterminalrequest?${filterBy}=${filterParams}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_GENERAL_TERMINALS_NEW_RES", httpRes);
    return httpRes;
  },
  FETCH_ALL_TERMINAL_TYPES: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/stores/listterminals`,
      method: "POST",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
