import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  staff_referral_code: [],
});

const getters = {
  GET_STAFF_REFERRAL_CODE: (state) => state.staff_referral_code,
};

const mutations = {
  SET_STAFF_REFERRAL_CODE: (state, data) => {
    state.staff_referral_code = data;
  },
};

const actions = {
  FETCH_STAFF_REFERRAL_CODE: async function({ commit }) {
    const httpReq = {
      url: `/v1/staff/retrieve`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_STAFF_REFERRAL_CODE", httpRes);
    return httpRes;
  },

  CREATE_STAFF_REFERRAL_CODE: async function(context, payload) {
    const httpReq = {
      url: `/v1/staff/create`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  DELETE_STAFF_REFERRAL_CODE: async function(context, payload) {
    const httpReq = {
      url: `/v1/staff/delete/${payload}`,
      method: "DELETE",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
