import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/filters";
import "./utils/components";
import "tailwindcss/tailwind.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import sentryPlugin from "./plugins/sentry.plugin"; // wherever you place your file
const isProduction = process.env.NODE_ENV === "production"; // returns

Vue.use(sentryPlugin, {
  isProduction,
  sentryDsn: `https://a5f24b024b964d36853e6b5c06f56928@o1270281.ingest.sentry.io/6762813`,
});

Vue.config.productionTip = false;

// Vue.use(Vuex);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
