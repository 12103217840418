import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  csr_agents: [],
  merchants: null,
};

const getters = {
  GET_CSR_ACCOUNT_LIST: (state) => state.csr_agents,
  GET_CSR_MERCHANTS: (state) => state.merchants,
};

const mutations = {
  SETUP_CSR_ACCOUNT_LIST: (state, data) => {
    state.csr_agents = data;
  },
  SETUP_CSR_MERCHANTS: (state, data) => {
    state.merchants = data;
  },
};

const actions = {
  FETCH_CSR_ACCOUNT_LIST: async function (
    { commit },
    { dateFilterChecker, date, searchActive, search, page }
  ) {
    let urlHandler = `/v1/salesagent/list-agents/corporate-sales?limit=100&page=${page}`;

    urlHandler += searchActive ? `&search=${search}` : "";

    urlHandler += dateFilterChecker
      ? `&signup_from_date=${date.startDate}&signup_to_date=${date.endDate}`
      : "";
    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_CSR_ACCOUNT_LIST", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSR_ACCOUNT_LIST_CSV: async function (
    { commit },
    { dateFilterChecker, date, searchActive, search }
  ) {
    let urlHandler = `/v1/salesagent/list-agents/corporate-sales?download=csv`;

    urlHandler += searchActive ? `&search=${search}` : "";

    urlHandler += dateFilterChecker
      ? `&signup_from_date=${date.startDate}&signup_to_date=${date.endDate}`
      : "";
    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_CSR_ACCOUNT_LIST", httpRes);
    return httpRes;
  },
  FETCH_CSR_ONBOARDED_MERCHANTS: async function (
    { commit },
    { dateFilterChecker, date, searchActive, search, page }
  ) {
    let urlHandler = `/v1/salesagent/merchants/corporate-sales?limit=100&page=${page}`;

    urlHandler += searchActive ? `&search=${search}` : "";

    urlHandler += dateFilterChecker
      ? `&signup_from_date=${date.startDate}&signup_to_date=${date.endDate}`
      : "";
    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_CSR_MERCHANTS", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSR_MERCHANT_LIST_CSV: async function (
    { commit },
    { dateFilterChecker, date, searchActive, search }
  ) {
    let urlHandler = `/v1/salesagent/merchants/corporate-sales?download=true`;

    urlHandler += searchActive ? `&search=${search}` : "";

    urlHandler += dateFilterChecker
      ? `&signup_from_date=${date.startDate}&signup_to_date=${date.endDate}`
      : "";
    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SETUP_CSR_ACCOUNT_LIST", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
