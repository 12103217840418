import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  wallet_crediting: {},
  wallet_businesses: {},
};

const getters = {
  GET_WALLET_CREDITING: (state) => state.wallet_crediting,
  GET_WALLET_BUSINESSES: (state) => state.wallet_businesses,
};

const mutations = {
  SET_WALLET_CREDITING: (state, data) => {
    state.wallet_crediting = data;
  },
  SET_WALLET_BUSINESSES: (state, data) => {
    state.wallet_businesses = data;
  },
};

const actions = {
  FETCH_WALLET_CREDITING: async function(context) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/walletcredit/requests`,
      method: "POST",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_CREDITING", httpRes);
    return httpRes;
  },
  WALLET_CREDITING_FETCH_SELECT_BUSINESS: async function(
    context,
    { businessEmail }
  ) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/walletcredit/business/${businessEmail}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_BUSINESSES", httpRes);
    return httpRes;
  },
  CREATE_WALLET_CREDITING_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/walletcredit/requests/new`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_BUSINESSES", httpRes);
    return httpRes;
  },
  CREATE_WALLET_DEBIT_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/walletcredit/requests/new/debit`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_BUSINESSES", httpRes);
    return httpRes;
  },
  APPROVE_WALLET_CREDITING_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/walletcredit/requests/authorise`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_BUSINESSES", httpRes);
    return httpRes;
  },
  APPROVE_WALLET_DEBIT_REQUEST: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `v1/walletcredit/requests/authorise/debit`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_WALLET_BUSINESSES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
