import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  retries: {},
};

const getters = {
  GET_ALL_RETRIES: (state) => state.retries,
};

const mutations = {
  SET_RETRIES_PORTAL: (state, data) => {
    state.retries = data;
  },
};

const actions = {
  FETCH_ALL_RETRIES: async function({ commit }) {
    const httpReq = {
      //&start_date=2023-09-21&end_date=2024-01-01&businessId=61eea98611843f000424ec03&blacklistedStatus
      url: `v1/kyc/kyc-info?status=PROCESSING`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIES_PORTAL", httpRes);
    return httpRes;
  },

  UPDATE_FEE: async function(context, { value }) {
    const httpReq = {
      url: `api/EWTraction/updatetractionbanktransferfee/${value} `,
      method: "POST",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_PAGINATED_FIXED_ACCOUNT: async function(
    context,
    { page, filter, date, searchFilter, search }
  ) {
    let urlHandler = filter
      ? `/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100&startdatetime=${date.startDate}&enddatetime=${date.endDate}`
      : `/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100`;

    urlHandler += searchFilter ? `&search=${search}` : ``;
    const httpReq = {
      url: urlHandler, //`/v1/payments/admin/fixedaccounts/businesses?page=${page}&limit=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SETUP_FIXED_ACCOUNTS_RES", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
