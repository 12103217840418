import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  fundTransfersRes: [],
};

const getters = {
  GET_FUND_TRANSFER: (state) => state.fundTransfersRes,
};

const mutations = {
  SET_FUND_TRANSFER_RES: (state, data) => {
    state.fundTransfersRes = data;
  },
};

const actions = {
  FETCH_FUNDS_TRANSFER: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/api/wallettransactions?pageNumber=1&pageSize=100",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_FUNDS_TRANSFER: async function(context, payload) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/api/wallettransactions?download=csv",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  REVERSE_FUNDS_TRANSFER: async function(context, { TransactionReference }) {
    const httpReq = {
      // const httpReq = {
      url: "/v1/api/wallettransactions/reversal",
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "POST",
      body: { TransactionReference },
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_FUNDS_TRANSFER: async function(context, { date, page }) {
    const httpReq = {
      // url: `/v1/businesses/getallbusinessesforfraud?page=${page}`,
      url: `/v1/api/wallettransactions?pageNumber=${page}&pageSize=100`,
      method: "POST",
      body: date,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  SEARCH_SINGLE_FUNDS_TRANSFER: async function({ commit }, { search, filter }) {
    const httpReq = {
      url: `/v1/api/wallettransactions?${filter}=${search}&pageSize=100`,
      method: "POST",
      body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_FUNDS_TRANSFER: async function(context, search) {
    const httpReq = {
      url: `/v1/api/wallettransactions?businessId=${search.id}&pageSize=100`,
      method: "POST",
      body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  FETCH_ALL_PROVIDERS: async function(context, search) {
    const httpReq = {
      url: `/v1/api/wallettransactions?businessId=${search.id}&pageSize=100`,
      method: "POST",
      body: {},
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  UPDATE_FUNDS_TRANSFER_CONFIG: async function(context, payload) {
    const httpReq = {
      url: `/v1/api/wallettransactions/config/update`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
  FETCH_TRANSFER_CONFIG: async function(context, payload) {
    const httpReq = {
      url: `/v1/api/wallettransactions/config`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SET_FUND_TRANSFER_RES", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
