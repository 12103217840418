import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import { GET_LOCAL_DB_DATA } from "../browser-db-config/localStorage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    redirect: { name: "QuickAccess" },
    children: [
      {
        path: "/overview",
        name: "Overview",
        component: () => import("../components/Overview.vue"),
      },
      {
        path: "/quick-access",
        name: "QuickAccess",
        component: () => import("../components/QuickAccess.vue"),
      },
      {
        path: "/payments-logs",
        name: "PaymentsLogs",
        component: () => import("../components/Payments.vue"),
      },
      {
        path: "/dataset",
        name: "Dataset",
        component: () => import("../components/Dataset.vue"),
      },
      {
        path: "/bank-accounts",
        name: "BankAccounts",
        component: () => import("../components/BankAccounts.vue"),
      },
      {
        path: "/broadcast-portal/:id",
        name: "BroadcastPortalEdit",
        component: () => import("../components/BroadcastPortalEdit.vue"),
      },
      {
        path: "/kyc-override-portal",
        name: "KYCOveridePortal",
        component: () => import("../components/KYCOveridePortal.vue"),
      },
      {
        path: "/broadcast-portal",
        name: "BroadcastPortal",
        component: () => import("../components/BroadcastPortal.vue"),
      },

      {
        path: "/business-payment-logs",
        name: "BusinesPaymentLogs",
        component: () => import("../components/BusinessPaymentLogs.vue"),
      },
      {
        path: "/downloads",
        name: "Downloads",
        component: () => import("../components/Downloads.vue"),
      },
      {
        path: "/fraud",
        name: "Fraud",
        component: () => import("../components/Fraud.vue"),
      },

      {
        path: "/funds-transfer",
        name: "FundsTransfer",
        component: () => import("../components/FundsTransfer.vue"),
      },
      {
        path: "/funds-transfer/:id",
        name: "ViewFundsTransfer",
        component: () => import("../components/ViewFundsTransfer.vue"),
      },
      {
        path: "/funds-reversal/:id",
        name: "ViewFundsReversal",
        component: () => import("../components/ViewFundsReversal.vue"),
      },
      {
        path: "/funds-reversal",
        name: "FundsReversal",
        component: () => import("../components/FundsReversal.vue"),
      },
      {
        path: "/all-verification",
        name: "Verifications",
        component: () => import("../components/Verifications.vue"),
      },
      {
        path: "/document-verification",
        name: "DocumentVerification",
        component: () => import("../components/DocumentVerification.vue"),
      },
      {
        path: "/all-verification/:id",
        name: "ViewVerification",
        component: () => import("../components/ViewVerification.vue"),
      },
      {
        path: "/document-verification/:id",
        name: "ViewDocumentVerification",
        component: () => import("../components/ViewDocumentVerification.vue"),
      },
      {
        path: "/document-verification/reject/:id",
        name: "DocumentVerificationRejectScreen",
        component: () =>
          import("../components/DocumentVerificationRejectScreen.vue"),
      },
      {
        path: "/address-verification",
        name: "AddressVerification",
        component: () => import("../components/AddressVerification.vue"),
      },
      {
        path: "/address-verification/:id",
        name: "ViewAddressVerification",
        component: () => import("../components/ViewAddressVerification.vue"),
      },
      {
        path: "/corporate-verification",
        name: "CorporateVerification",
        component: () => import("../components/CorporateVerification.vue"),
      },
      {
        path: "/corporate-verification/:id",
        name: "ViewCorporateVerification",
        component: () => import("../components/ViewCorporateVerification.vue"),
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("../components/Wallet.vue"),
      },
      {
        path: "/new-wallet-crediting-portal/:id/:email",
        name: "NewWalletCreditingPortal",
        component: () => import("../components/NewWalletCreditingPortal.vue"),
      },
      {
        path: "/wallet-crediting-portal-select-business",
        name: "WalletCreditingPortalSelectBusiness",
        component: () =>
          import("../components/WalletCreditingPortalSelectBusiness.vue"),
      },
      {
        path: "/wallet/transfer-fee",
        name: "WalletTransferFee",
        component: () => import("../components/TransferFee.vue"),
      },
      {
        path: "/wallet/cashback",
        name: "WalletCashBack",
        component: () => import("../components/CashBack.vue"),
      },
      {
        path: "/wallet-management-portal",
        name: "WalletCreditingPortal",
        component: () => import("../components/WalletCreditingPortal.vue"),
      },
      {
        path: "/all-wallets-transactions-logs",
        name: "AllWalletsTransactionsLogs",
        component: () => import("../components/AllWalletsTransactionsLogs.vue"),
      },
      {
        path: "/transaction-reversal",
        name: "TransactionReversal",
        component: () => import("../components/TransactionReversal.vue"),
      },
      {
        path: "/loans",
        name: "Loans",
        component: () => import("../components/Loans.vue"),
      },
      {
        path: "/loans/:id",
        name: "EditLoan",
        component: () => import("../components/EditLoan.vue"),
      },
      {
        path: "/loans/new-loan",
        name: "NewLoan",
        component: () => import("../components/NewLoan.vue"),
      },
      {
        path: "/reporting-page",
        name: "ReportingPage",
        component: () => import("../components/LoansReportingPage.vue"),
      },
      {
        path: "/reporting-page/view",
        name: "ReportingPageView",
        component: () => import("../components/LoansReportingPageView.vue"),
      },
      {
        path: "/terminals",
        name: "Terminals",
        component: () => import("../components/Terminals.vue"),
      },
      {
        path: "/request-terminals",
        name: "Request",
        component: () => import("../components/RequestTerminals.vue"),
      },
      {
        path: "/terminal-pricings",
        name: "Pricings",
        component: () => import("../components/TerminalPricings.vue"),
      },
      {
        path: "/view-transaction-monitoring-by-route/:id",
        name: "ViewTransactionMonitoringByRoute",
        component: () =>
          import("../components/ViewTransactionMonitoringPortalByRoute.vue"),
      },
      {
        path: "/view-transaction-monitoring-by-failed-routes",
        name: "TransactionMonitoringPortalFailedByRoute",
        component: () =>
          import("../components/TransactionMonitoringPortalFailedByRoute.vue"),
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../components/Notifications.vue"),
      },
      {
        path: "/notifications/create",
        name: "CreateNotifications",
        component: () => import("../components/CreateNotification.vue"),
      },
      {
        path: "/notifications/preview/:id",
        name: "PreviewNotifications",
        component: () => import("../components/PreviewNotification.vue"),
      },
      {
        path: "/pos-terminal-receipt",
        name: "POSReceipt",
        component: () => import("../components/POSTerminalReceipt.vue"),
      },
      {
        path: "/pnd-accounts",
        name: "PndAccounts",
        component: () => import("../components/PndAccounts.vue"),
      },
      {
        path: "/fixed-account-blacklisted",
        name: "FixedAccountBlacklisted",
        component: () => import("../components/FixedAccountBlacklisted.vue"),
      },
      {
        path: "/fixed-accounts",
        name: "FixedAccounts",
        component: () => import("../components/FixedAccounts.vue"),
      },
      {
        path: "/terminal-requests-all",
        name: "TerminalRequestsAll",
        component: () => import("../components/TerminalRequestsAll.vue"),
      },
      {
        path: "/transaction-monitoring-portal-2",
        name: "TransactionMonitoringPortalTwo",
        component: () =>
          import("../components/TransactionMonitoringPortalTwo.vue"),
      },
      {
        path: "/view-transaction-monitoring-by-bank/:id",
        name: "ViewTransactionMonitoringByBank",
        component: () =>
          import("../components/ViewTransactionMonitoringByBank.vue"),
      },
      {
        path: "/terminals/:id",
        name: "EditTerminal",
        component: () => import("../components/EditTerminal.vue"),
      },
      {
        path: "/terminal-mastersheet",
        name: "TerminalMastersheet",
        component: () => import("../components/TerminalMastersheet.vue"),
      },
      {
        path: "/merchant-visitation-log",
        name: "MerchantVisitationLog",
        component: () => import("../components/MerchantVisitationLog.vue"),
      },
      {
        path: "/retry-portal",
        name: "RetryPortal",
        component: () => import("../components/RetryPortal.vue"),
      },
      {
        path: "/view-merchant-visitation-log/",
        name: "ViewMerchantVisitationLog",
        props: (route) => ({
          ...route.params,
        }),
        component: () => import("../components/ViewMerchantVisitationLog.vue"),
      },
      {
        path: "/terminal-location-sheet",
        name: "TerminalLocationSheet",
        component: () => import("../components/TerminalLocationSheet.vue"),
      },
      {
        path: "/terminal-weekly-visitation-sheet",
        name: "TerminalWeeklyVisitationSheet",
        component: () =>
          import("../components/TerminalWeeklyVisitationSheet.vue"),
      },
      {
        path: "/terminal/new",
        name: "NewTerminal",
        component: () => import("../components/NewTerminal.vue"),
      },
      {
        path: "/terminal-deployments",
        name: "TerminalDeployments",
        component: () => import("../components/TerminalDeployments.vue"),
      },
      {
        path: "/wema-bank-account-query",
        name: "WEMABankAccountQuery",
        component: () => import("../components/WEMABankAccountQuery.vue"),
      },
      {
        path: "/terminal-override-code",
        name: "TerminalOverrideCode",
        component: () => import("../components/TerminalOverrideCode.vue"),
      },
      {
        path: "/new-terminal-override-code",
        name: "NewTerminalOverrideCode",
        component: () => import("../components/NewTerminalOverrideCode.vue"),
      },
      {
        path: "/rules-manager",
        name: "RulesManager",
        component: () => import("../components/RulesManager.vue"),
      },
      {
        path: "/settlement-accounts",
        name: "SettlementAccounts",
        component: () => import("../components/SettlementAccounts.vue"),
      },
      {
        path: "/upload-portal",
        name: "Upload",
        component: () => import("../components/Upload.vue"),
      },
      {
        path: "/all-bulk-upload",
        name: "AllBulkUploads",
        component: () => import("../components/BulkUploads.vue"),
      },
      {
        path: "/bulk-status/:id",
        name: "BulkStatus",
        component: () => import("../components/BulkUploads.vue"),
      },
      {
        path: "/terminal-requests-key-accounts-view",
        name: "TerminalRequestKeyAccountsView",
        component: () =>
          import("../components/TerminalRequestKeyAccountsView.vue"),
      },
      {
        path: "/terminal-requests-telesales-view",
        name: "TerminalRequestTelesalesView",
        component: () =>
          import("../components/TerminalRequestTelesalesView.vue"),
      },
      {
        path: "/terminal-requests-isa-view",
        name: "TerminalRequestISAView",
        component: () => import("../components/TerminalRequestISAView.vue"),
      },
      {
        path: "/terminal-requests-dsr-view",
        name: "TerminalRequestDSRView",
        component: () => import("../components/TerminalRequestDSRView.vue"),
      },
      {
        path: "/terminal-requests-loan-view",
        name: "TerminalRequestLoanView",
        component: () => import("../components/TerminalRequestLoanView.vue"),
      },
      {
        path: "/terminal-uploads",
        name: "TerminalUploads.vue",
        component: () => import("../components/TerminalUploads.vue"),
      },
      {
        path: "/unmap-terminals",
        name: "UnmapTerminals",
        component: () => import("../components/UnmapTerminals.vue"),
      },
      {
        path: "/terminal-delivery-portal",
        name: "TerminalDeliveryPortal",
        component: () => import("../components/TerminalDeliveryPortal.vue"),
      },
      {
        path: "/transaction-monitoring-portal",
        name: "TransactionMontoringPortal",
        component: () =>
          import("../components/TransactionMonitoringPortal.vue"),
      },
      {
        path: "/transaction-monitoring-portal/:id",
        name: "ViewTransactionMonitoringPortal",
        component: () =>
          import("../components/ViewTransactionMonitoringPortal.vue"),
      },
      {
        path: "/funds-transfer-provider-portal",
        name: "FundsTransferProviderPortal",
        component: () =>
          import("../components/FundsTransferProviderPortal.vue"),
      },
      {
        path: "/cashback-activation-portal",
        name: "CashbackActivationPortal",
        component: () => import("../components/CashbackActivationPortal.vue"),
      },
      {
        path: "/providus-repush",
        name: "ProvidusRepush",
        component: () => import("../components/ProvidusRepush.vue"),
      },
      {
        path: "/providus-rename",
        name: "ProvidusRename",
        component: () => import("../components/ProvidusRename.vue"),
      },
      {
        path: "/wallet-transaction-logs",
        name: "WalletTransactionLog",
        component: () => import("../components/WalletTransactionLog.vue"),
      },
      {
        path: "/wallet-transaction-logs/:id",
        name: "ViewWalletTransactionLog",
        component: () => import("../components/ViewWalletTransactionLog.vue"),
      },
      {
        path: "/business-logs",
        name: "BusinessLogs",
        component: () => import("../components/BusinessLogs.vue"),
      },
      {
        path: "/terminal-requests-mobile-and-web-view",
        name: "TerminalRequestMobileAppView",
        component: () =>
          import("../components/TerminalRequestMobileAppView.vue"),
      },
      {
        path: "/terminal-requests-all",
        name: "TerminalRequestAll",
        component: () => import("../components/TerminalRequestsAll.vue"),
      },
      {
        path: "/merchant-visitation",
        name: "MerchantVisitation",
        component: () => import("../components/MerchantVisitation.vue"),
      },
      {
        path: "/new-merchant-visit",
        name: "NewMerchantVisit",
        component: () => import("../components/NewMerchantVisit.vue"),
      },
      {
        path: "/support-deployment",
        name: "SupportDeployment",
        component: () => import("../components/SupportDeployment.vue"),
      },
      {
        path: "/retrieval-response",
        name: "RetrievalResponse",
        component: () => import("../components/RetrievalResponse.vue"),
      },
      {
        path: "/form-responses",
        name: "FormResponses",
        component: () => import("../components/FormResponses.vue"),
      },
      {
        path: "/sim-management",
        name: "SimManagement",
        component: () => import("../components/SimManagement.vue"),
      },
      {
        path: "/pricing-bands",
        name: "PricingBandPortal",
        component: () => import("../components/PricingBandPortal.vue"),
      },
      {
        path: "/pricing-bands-assign",
        name: "PricingBandAssignPortal",
        component: () => import("../components/PricingBandAssignPortal.vue"),
      },
      {
        path: "/pricing-bands-assign-business/:id/:merchant",
        name: "PricingBandAssignBusinessPortal",
        component: () =>
          import("../components/PricingBandAssignBusinessPortal.vue"),
      },
      {
        path: "/sendinblue-email-synch",
        name: "SendinblueEmailSynch",
        component: () => import("../components/SendinblueEmailSynch.vue"),
      },
      {
        path: "/bulk-sweep-history",
        name: "BulkSweepHistory",
        component: () => import("../components/BulkSweepHistory.vue"),
      },
      {
        path: "/bulk-sweep-new-transfer",
        name: "BulkSweepNewTransfer",
        component: () => import("../components/BulkSweepNewTransfer.vue"),
      },
      {
        path: "/sweep-request/:id/:bank/:acctno/:acctname",
        name: "SweepRequest",
        component: () => import("../components/SweepRequest.vue"),
      },
      {
        path: "/terminal-history",
        name: "TerminalHistory",
        component: () => import("../components/TerminalHistory.vue"),
      },
      {
        path: "/terminal-deployment-mso",
        name: "TerminalDeploymentMSO",
        component: () => import("../components/TerminalDeploymentMSO.vue"),
      },
      {
        path: "/terminal-deployment-sales-agent",
        name: "TerminalDeploymentSalesAgent",
        component: () =>
          import("../components/TerminalDeploymentSalesAgent.vue"),
      },
      {
        path: "/csr-account-list",
        name: "CSRAccountList",
        component: () => import("../components/CSRAccountList.vue"),
      },
      {
        path: "/csr-terminal-deployment",
        name: "CSRTerminalDeployment",
        component: () => import("../components/CSRTerminalDeployment.vue"),
      },
      {
        path: "/csr-onboarded-merchants",
        name: "CSROnboardedMerchants",
        component: () => import("../components/CSROnboardedMerchants.vue"),
      },
      {
        path: "/middleware-switching-portal",
        name: "MiddlewareSwitchingPortal.vue",
        component: () => import("../components/MiddlewareSwitchingPortal.vue"),
      },
      {
        path: "/schedules-management",
        name: "SchedulesManagement",
        component: () => import("../components/SchedulesManagement.vue"),
      },
      {
        path: "/website-forms",
        name: "WebsiteForms",
        component: () => import("../components/WebsiteForms.vue"),
      },
      {
        path: "/inactive-accounts",
        name: "InactiveAccounts",
        component: () => import("../components/InactiveAccounts.vue"),
      },
      {
        path: "/website-forms/:id",
        name: "ViewWebsiteForms",
        component: () => import("../components/ViewWebsiteForms.vue"),
      },
      {
        path: "/request-terminals-keyaccounts",
        name: "TerminalRequestFormKeyAccounts",
        component: () =>
          import("../components/TerminalRequestFormKeyAccounts.vue"),
      },
      {
        path: "/request-terminals-telesales",
        name: "TerminalRequestFormTelesales",
        component: () =>
          import("../components/TerminalRequestFormTelesales.vue"),
      },
      {
        path: "/vfd-transaction-query",
        name: "VFDTransactionQuery",
        component: () => import("../components/VFDTransactionQuery.vue"),
      },
      {
        path: "/waitlist",
        name: "Waitlist",
        component: () => import("../components/Waitlist.vue"),
      },
      {
        path: "/merchant-support-visitation-form-response",
        name: "MerchantSupportVisitationList",
        component: () =>
          import("../components/MerchantSupportVisitationList.vue"),
      },
      {
        path: "/terminal-requests-cross-selling-view",
        name: "TerminalRequestCrossSellingView",
        component: () =>
          import("../components/TerminalRequestCrossSellingView.vue"),
      },
      {
        path: "/staff-referral-code",
        name: "StaffReferralCode",
        component: () => import("../components/StaffReferralCode.vue"),
      },
      {
        path: "/new-staff-referral-code",
        name: "NewStaffReferralCode",
        component: () => import("../components/NewStaffReferralCode.vue"),
      },
      {
        path: "/request-terminals-cross-selling",
        name: "TerminalRequestFormCrossSelling",
        component: () =>
          import("../components/TerminalRequestFormCrossSelling.vue"),
      },
      {
        path: "/password-reset",
        name: "PasswordReset",
        component: () => import("../components/PasswordResetLink.vue"),
      },
      {
        path: "/admin",
        name: "Admin",
        component: () => import("../components/Admin.vue"),
      },
      {
        path: "/new-admin",
        name: "NewAdmin",
        component: () => import("../components/NewAdmin.vue"),
      },
      {
        path: "/permissions",
        name: "Permissions",
        component: () => import("../components/Permissions.vue"),
      },
      {
        path: "/permissions-department",
        name: "PermissionsDepartment",
        component: () => import("../components/PermissionsDepartment.vue"),
      },
      {
        path: "/permissions-users",
        name: "PermissionsUser",
        component: () => import("../components/PermissionsUser.vue"),
      },
      {
        path: "/permissions-user-by-id/:id/:name",
        name: "PermissionsUserById",
        component: () => import("../components/PermissionsUserById.vue"),
      },
      {
        path: "/permissions-department-by-id/:id/:name",
        name: "PermissionsDepartmentById",
        component: () => import("../components/PermissionsDepartmentById.vue"),
      },
      {
        path: "/no-permission",
        name: "NoPermission",
        component: () => import("../components/NoPermission.vue"),
      },
      {
        path: "/error",
        name: "ErrorPermission",
        component: () => import("../components/ErrorPermission.vue"),
      },
    ],
  },
  {
    path: "/traction-chargeback-form",
    name: "TractionChargebackForm",
    component: () => import("../components/TractionChargebackForm.vue"),
  },
  {
    path: "/traction-staff-verification-portal",
    name: "TractionStaffVerificationPortal",
    component: () =>
      import("../components/TractionStaffVerificationPortal.vue"),
  },
  {
    path: "/traction-support-form",
    name: "TractionSupportForm",
    component: () => import("../components/TractionSupportForm.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Login" &&
    to.name !== "ResetPassword" &&
    to.name !== "TractionChargebackForm" &&
    to.name !== "TractionSupportForm" &&
    to.name !== "TractionStaffVerificationPortal" &&
    !GET_LOCAL_DB_DATA("traction-app-user-data") &&
    !GET_LOCAL_DB_DATA("traction-app-current-user-business-data")
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
