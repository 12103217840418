var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex flex-col overflow-visible"},[_c('div',{staticClass:"flex-row mt-2 sm:mt-0"},[_c('button',{staticClass:"sm:hidden flex fixed bg-gray-50",on:{"click":function($event){_vm.mobileMenuShow = !_vm.mobileMenuShow}}},[_c('svg',{staticClass:"bi bi-list text-blue-800",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"}})])]),_c('aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenuShow),expression:"mobileMenuShow"}],staticClass:"bg-gray h-screen shadow-md sm:hidden z-50"},[_c('div',{staticClass:"p-4 h-full bg-gray-800 flex flex-col",staticStyle:{"overflow":"scroll"}},[_vm._m(0),_c('div',{staticClass:"flex flex-col flex-grow flex-shrink justify-between"},[_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"bg-gray-900 text-white p-5 rounded cursor-pointer"},[_vm._v(" Workspace ")]),_vm._l((_vm.links),function(link){return _c('div',{key:link.id},[_c('router-link',{key:link.id,staticClass:"dropdown-btn btn-full-width p-2 rounded mt-2 cursor-pointer hover:bg-gray-700 hover:text-blue-300",class:_vm.$route.name.includes(link.routeName)
                    ? 'bg-gray-700 text-blue-300'
                    : 'bg-gray-900 text-white',attrs:{"tag":!link.subLinks ? 'div' : 'button',"to":("" + (link.page))}},[_vm._v(" "+_vm._s(link.text)+" "),(link.subLinks)?_c('svg',{staticClass:"w-4 h-4 mr-2",staticStyle:{"display":"inline-block"},attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})]):_vm._e()]),_c('div',{staticClass:"dropdown-container"},_vm._l((link.subLinks),function(subLink){return _c('router-link',{key:subLink.id,staticClass:"p-2 rounded mt-2 cursor-pointer hover:bg-gray-500 hover:text-blue-300",class:_vm.$route.name.includes(subLink.routeName)
                      ? 'bg-gray-500 text-blue-300'
                      : 'bg-gray-900 text-white',attrs:{"to":("" + (subLink.page))}},[_vm._v(" "+_vm._s(subLink.text)+" ")])}),1)],1)})],2),_c('div',{staticClass:"mt-2 mb-0"},[_c('button',{staticClass:"rounded flex items-center w-full p-3 text-white bg-red-500 cursor-pointer text-center text-sm",on:{"click":_vm.logout}},[_c('svg',{staticClass:"w-4 h-4 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fillRule":"evenodd","d":"M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z","clipRule":"evenodd"}})]),_c('span',{staticClass:"font-semibold"},[_vm._v("Logout")])])])])])])]),_c('main',{staticClass:"flex w-full h-screen"},[_c('aside',{staticClass:"w-80 h-full bg-gray shadow-md hidden sm:block",staticStyle:{"overflow":"scroll"}},[_c('div',{staticClass:"p-4 h-full bg-gray-800 flex flex-col",staticStyle:{"overflow":"scroll"}},[_vm._m(1),_c('div',{staticClass:"flex flex-col flex-grow flex-shrink justify-between"},[_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"bg-gray-900 text-white p-5 rounded cursor-pointer"},[_vm._v(" Workspace ")]),_vm._l((_vm.links),function(link){return _c('div',{key:link.id},[_c('router-link',{key:link.id,staticClass:"dropdown-btn btn-full-width p-2 rounded mt-2 cursor-pointer hover:bg-gray-700 hover:text-blue-300",class:_vm.$route.name.includes(link.routeName)
                    ? 'bg-gray-700 text-blue-300'
                    : 'bg-gray-900 text-white',attrs:{"tag":!link.subLinks ? 'div' : 'button',"to":("" + (link.page))}},[_vm._v(" "+_vm._s(link.text)+" "),(link.subLinks)?_c('svg',{staticClass:"w-4 h-4 mr-2",staticStyle:{"display":"inline-block"},attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})]):_vm._e()]),_c('div',{staticClass:"dropdown-container"},_vm._l((link.subLinks),function(subLink){return _c('router-link',{key:subLink.id,staticClass:"p-2 rounded mt-2 cursor-pointer hover:bg-gray-500 hover:text-blue-300",class:_vm.$route.name.includes(subLink.routeName)
                      ? 'bg-gray-500 text-blue-300'
                      : 'bg-gray-900 text-white',attrs:{"to":("" + (subLink.page))}},[_vm._v(" "+_vm._s(subLink.text)+" ")])}),1)],1)})],2),_c('div',{staticClass:"mt-2 mb-0"},[_c('button',{staticClass:"rounded flex items-center w-full p-3 text-white bg-red-500 cursor-pointer text-center text-sm",on:{"click":_vm.logout}},[_c('svg',{staticClass:"w-4 h-4 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fillRule":"evenodd","d":"M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z","clipRule":"evenodd"}})]),_c('span',{staticClass:"font-semibold"},[_vm._v("Logout")])])])])])]),_c('section',{staticClass:"w-full p-4 overflow-scroll bg-gray-50"},[_c('router-view')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-baseline my-5 flex-grow-0 flex-shrink"},[_c('img',{staticClass:"w-7 h-7 rounded-full",attrs:{"src":"https://tractionapps.co/wp-content/uploads/2021/05/cropped-tractionapp-favicon-32x32.png"}}),_c('span',{staticClass:"pl-2 text-white"},[_vm._v("Traction Admin")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-baseline my-5 flex-grow-0 flex-shrink"},[_c('img',{staticClass:"w-7 h-7 rounded-full",attrs:{"src":"https://tractionapps.co/wp-content/uploads/2021/05/cropped-tractionapp-favicon-32x32.png"}}),_c('span',{staticClass:"pl-2 text-white"},[_vm._v("Traction Admin")])])}]

export { render, staticRenderFns }