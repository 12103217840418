// 15:26 Nov 23, 2022, Mars [223]
//This would be used as a central point for endpoints used across all components
//They won't be using the states but only actions i.e they will return their values without mutating it

import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  FETCH_MERCHANT_USING_NAME_OR_EMAIL: async function({ commit }, { search }) {
    const httpReq = {
      url: `/v1/businesses/nameoremail/${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_TRACTION_BANK_LISTS: async function({ commit }) {
    const httpReq = {
      url: `/v1/payments/gettractionbanklist`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  ///salesagent/list-businesses?email
  FETCH_MERCHANT_USING_EMAIL_DETAILED: async function({ commit }, { search }) {
    const httpReq = {
      url: `/v1/salesagent/list-businesses?email=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_VFD_TRANSACTIONS: async function({ commit }, { date, accountNumber }) {
    let urlParams = `/v1/vfd/transactions?fromDate=${date.startDate}&endDate=${date.endDate}`;
    urlParams += accountNumber ? `&accountNo=${accountNumber}` : "";
    const httpReq = {
      url: urlParams,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_RESET_LINK: async function() {
    const httpReq = {
      url: `/v1/auth/recover/password_reset/link`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  LOAN_DASHBOARD: async function(_, payload) {
    const httpReq = {
      url: `/v2/loans/loan-tracker-all`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  async CHECK_BUSINESS_EMAIL(_, { email }) {
    const httpReq = {
      method: "GET",
      url: `/v1/auth/check/${email}`,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  HANDLE_FILE_UPLOAD: async function(context, payload) {
    const uploadFormData = new FormData();
    uploadFormData.append("file", payload);

    const httpReq = {
      url: `/v1/uploads/`,
      method: "POST",
      authRequest: true,
      body: uploadFormData,
      fileUpload: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_STAFF_BY_STAFF_CODE: async function(context, payload) {
    const httpReq = {
      url: `/v1/staff/retrieve/public/${payload.staffCode}`,
      method: "GET",
      authRequest: false,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
