import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  support_deployments: [],
  merchant_visitations: [],
  retrieval_responses: [],
  sim_management: [],
  schedule_management: [],
});

const getters = {
  GET_SUPPORT_DEPLOYMENT: (state) => state.support_deployments,
  GET_MERCHANT_VISITATIONS: (state) => state.merchant_visitations,
  GET_RETRIEVAL_RESPONSES: (state) => state.retrieval_responses,
  GET_SIM_MANAGEMENT: (state) => state.sim_management,
  GET_SCHEDULE_MANAGEMENT: (state) => state.schedule_management,
};

const mutations = {
  SET_SUPPORT_DEPLOYMENT: (state, data) => {
    state.support_deployments = data;
  },
  SET_MERCHANT_VISITATION: (state, data) => {
    state.merchant_visitations = data;
  },
  SET_RETRIEVAL_RESPONSES: (state, data) => {
    state.retrieval_responses = data;
  },
  SET_SIM_MANAGEMENT: (state, data) => {
    state.sim_management = data;
  },
  SET_SCHEDULE_MANAGEMENT: (state, data) => {
    state.schedule_management = data;
  },
};

const actions = {
  //Support Deployment
  FETCH_SUPPORT_DEPLOYMENT: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getdeployment_support`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  PAGINATE_SUPPORT_DEPLOYMENT: async function(
    { commit },
    { page, filterType, filterActive, date, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterActive && filterType === "createDate") {
      urlHandler += `&create_date${date.creationDate}`;
    } else if (filterActive && filterType === "dateDeployed") {
      urlHandler += `&start_date=${date.startDate}&end_date=${date.endDate}`;
    }

    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/getdeployment_support?page=${page}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  SEARCH_SUPPORT_DEPLOYMENT: async function({ commit }, search) {
    const httpReq = {
      url: `/v1/salesagent/getdeployment_support?search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  FETCH_DATED_PAGINATE_SUPPORT_DEPLOYMENT: async function(
    { commit },
    { date, filterType }
  ) {
    console.log("FilterType", filterType);
    const urlPath =
      filterType === "createDate"
        ? `/v1/salesagent/getdeployment_support?create_date=${date.creationDate}`
        : `/v1/salesagent/getdeployment_support?start_date=${date.startDate}&end_date=${date.endDate}`;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_SUPPORT_DEPLOYMENT: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getdeployment_support?download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  DOWNLOAD_DATED_CSV_SUPPORT_DEPLOYMENT: async function(
    { commit },
    { startDate, endDate }
  ) {
    const httpReq = {
      url: `/v1/salesagent/getdeployment_support?download=csv&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SUPPORT_DEPLOYMENT", httpRes);
    return httpRes;
  },
  //Merchant Visitation
  //We designed a whole new endpoint for dealing with merchant visitation - ISAIT 859
  //The old endpooint: /v1/salesagent/getvisitation_form
  //The new endpoint: /v1/salesagent/get-visitation-form
  FETCH_MERCHANT_VISITATION: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },
  PAGINATE_MERCHANT_VISITATIONS: async function(
    { commit },
    { page, filterParams, filterActive, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterActive) {
      urlHandler += `&start_date=${filterParams.startDate}&end_date=${filterParams.endDate}`;
    }

    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form?page=${page}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },
  SEARCH_MERCHANT_VISITATION: async function({ commit }, search) {
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form?search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },
  FETCH_DATED_PAGINATE_MERCHANT_VISITATION: async function(
    { commit },
    { filterParams, filterType }
  ) {
    console.log(filterParams);
    // const urlPath =
    //   filterType === "createDate"
    //     ? `/v1/salesagent/get-visitation-form?create_date=${date.creationDate}`
    //     : `/v1/salesagent/get-visitation-form?start_date=${date.startDate}&end_date=${date.endDate}`;
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form?start_date=${filterParams.startDate}&end_date=${filterParams.endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_MERCHANT_VISITATION: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form?download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },

  DOWNLOAD_DATED_CSV_MERCHANT_VISITATION: async function(
    { commit },
    { startDate, endDate }
  ) {
    const httpReq = {
      url: `/v1/salesagent/get-visitation-form?download=csv&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MERCHANT_VISITATION", httpRes);
    return httpRes;
  },

  FETCH_SALES_AGENT: async function({ commit }) {
    ///salesagent/list-agents
    const httpReq = {
      url: `/v1/salesagent/list-agents`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },
  SCHEDULE_MERCHANT_VISITATION: async function({ commit }, { payload }) {
    ///salesagent/list-agents
    const httpReq = {
      url: `/v1/salesagent/schedule-visitation`,
      method: "POST",
      body: payload,
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },

  //Retrieval Reponses
  FETCH_RETRIEVAL_RESPONSES: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getretrieval_form`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },
  PAGINATE_RETRIEVAL_RESPONSES: async function(
    { commit },
    { page, filterType, filterActive, date, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterActive && filterType === "createDate") {
      urlHandler += `&create_date=${date.creationDate}`;
    } else if (filterActive && filterType === "dateDeployed") {
      urlHandler += `&start_date=${date.startDate}&end_date=${date.endDate}`;
    }

    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/getretrieval_form?page=${page}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },

  SEARCH_RETRIEVAL_RESPONSES: async function({ commit }, search) {
    const httpReq = {
      url: `/v1/salesagent/getretrieval_form?search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },
  FETCH_DATED_PAGINATE_RETRIEVAL_RESPONSES: async function(
    { commit },
    { startDate, endDate, filterType, creationDate }
  ) {
    const urlPath =
      filterType === "createDate"
        ? `/v1/salesagent/getretrieval_form?create_date${creationDate}`
        : `/v1/salesagent/getretrieval_form?start_date=${startDate}&end_date=${endDate}`;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_RETRIEVAL_RESPONSES: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getretrieval_form?download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },

  DOWNLOAD_DATED_CSV_RETRIEVAL_RESPONSES: async function(
    { commit },
    { startDate, endDate }
  ) {
    const httpReq = {
      url: `/v1/salesagent/getretrieval_form?download=csv&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_RETRIEVAL_RESPONSES", httpRes);
    return httpRes;
  },
  //Sim Management
  FETCH_SIM_MANAGEMENT: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getsim_management`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },
  PAGINATE_SIM_MANAGEMENT: async function(
    { commit },
    { page, filterType, filterActive, date, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterActive && filterType === "createDate") {
      urlHandler += `&create_date=${date.creationDate}`;
    } else if (filterActive && filterType === "dateDeployed") {
      urlHandler += `&start_date=${date.startDate}&end_date=${date.endDate}`;
    }

    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/getsim_management?page=${page}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },

  SEARCH_SIM_MANAGEMENT: async function({ commit }, search) {
    const httpReq = {
      url: `/v1/salesagent/getsim_management?search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },
  FETCH_DATED_PAGINATE_SIM_MANAGEMENT: async function(
    { commit },
    { date, filterType }
  ) {
    const urlPath =
      filterType === "createDate"
        ? `/v1/salesagent/getsim_management?create_date=${date.creationDate}`
        : `/v1/salesagent/getsim_management?start_date=${date.startDate}&end_date=${date.endDate}`;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },
  DOWNLOAD_CSV_SIM_MANAGEMENT: async function({ commit }) {
    const httpReq = {
      url: `/v1/salesagent/getsim_management?download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },

  DOWNLOAD_DATED_CSV_SIM_MANAGEMENT: async function(
    { commit },
    { startDate, endDate }
  ) {
    const httpReq = {
      url: `/v1/salesagent/getsim_management?download=csv&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SIM_MANAGEMENT", httpRes);
    return httpRes;
  },

  //Schedule Management
  FETCH_SCHEDULE_MANAGEMENT: async function({ commit }) {
    const httpReq = {
      // /salesagent/all-schedule-visitations?limit=3&start_date=2022-11-29&end_date=2022-11-30&search=test casimer&page=4&status=completed&agenda=&location=lagos
      url: `/v1/salesagent/all-schedule-visitations?limit=100`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SCHEDULE_MANAGEMENT", httpRes);
    return httpRes;
  },
  SEARCH_SCHEDULED_MGT: async function({ commit }, search) {
    const httpReq = {
      // /salesagent/all-schedule-visitations?limit=3&start_date=2022-11-29&end_date=2022-11-30&search=test casimer&page=4&status=completed&agenda=&location=lagos
      url: `/v1/salesagent/all-schedule-visitations?limit=100&search=${search}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SCHEDULE_MANAGEMENT", httpRes);
    return httpRes;
  },
  FETCH_FILTERED_SCHEDULE_MANAGEMENT: async function(
    { commit },
    { filterType, filterParams, filterActive, date, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterType === "filterByVisitationAgenda") {
      urlHandler += `&agenda=${filterParams}`;
    } else if (filterType === "filterByLocation") {
      urlHandler += `&location=${filterParams}`;
    } else if (filterType === "filterByVisitationStatus") {
      urlHandler += `&status=${filterParams}`;
    } else if (filterType === "filterByVisitationDate") {
      urlHandler += `&start_date=${filterParams.startDate}&end_date=${filterParams.endDate}`;
    }

    // urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/all-schedule-visitations?page=1${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SCHEDULE_MANAGEMENT", httpRes);
    return httpRes;
  },
  PAGINATE_SCHEDULES_MGT: async function(
    { commit },
    { page, filterType, filterParams, filterActive, date, searchActive, search }
  ) {
    let urlHandler = "";
    if (filterActive && filterType === "filterByVisitationAgenda") {
      urlHandler += `&agenda=${filterParams}`;
    } else if (filterActive && filterType === "filterByLocation") {
      urlHandler += `&location=${filterParams}`;
    } else if (filterActive && filterType === "filterByVisitationStatus") {
      urlHandler += `&status=${filterParams}`;
    } else if (filterActive && filterType === "filterByVisitationDate") {
      urlHandler += `&start_date=${date.startDate}&end_date=${date.endDate}`;
    }

    urlHandler += searchActive ? `&search=${search}` : ``;
    const httpReq = {
      url: `/v1/salesagent/all-schedule-visitations?page=${page}${urlHandler}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_SCHEDULE_MANAGEMENT", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
