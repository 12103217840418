import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  monitoring: [],
};

const getters = {
  GET_MONITORING: (state) => state.monitoring,
};

const mutations = {
  SET_MONITORING: (state, data) => {
    state.monitoring = data;
  },
};
const actions = {
  FETCH_ALL_TRANSACTION_PORTAL: async function({ commit }, { pageNumber }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?pageNumber=${pageNumber}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_MONITORING: async function({ commit }, { id }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?id=${id}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },

  FETCH_RECEIPT_DETAILS: async function({ commit }, { terminal_id }) {
    const httpReq = {
      url: `v1/payments/getReceiptDetails?terminal_id=${terminal_id}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_MONITORING", httpRes);
    return httpRes;
  },

  FETCH_DATED_TRANSACTION_PORTAL: async function({ commit }, { date }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?pageNumber=1&startDate=${date.startDate}&endDate=${date.endDate}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },
  FETCH_DATED_TRANSACTION_PORTAL_CSV: async function({ commit }, { date }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?pageNumber=1&startDate=${date.startDate}&endDate=${date.endDate}&download=csv`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },
  SEARCH_TRANSACTION_PORTAL: async function({ commit }, { search, status }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?${status}=${search}`,
      method: "GET",
      authRequest: true,
      // body: date,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },
  DOWNLOAD_TRANSACTION_PORTAL: async function({ commit }) {
    const httpReq = {
      url: `v1/payments/getTransactionMonitoring?download=true`,
      method: "GET",
      authRequest: true,
      // body: date,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_MONITORING", httpRes);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
