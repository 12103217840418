import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import bankAccounts from "./modules/bank-accounts";
import paymentLogs from "./modules/payment-logs";
import dataSet from "./modules/dataset";
import terminals from "./modules/terminals";
import loans from "./modules/loans";
import batch from "./modules/batch";
import notifications from "./modules/notifications";
import verification from "./modules/verification";
import wallet from "./modules/wallet";
import cashback from "./modules/cashback";
import downloads from "./modules/downloads";
import fundsTransfer from "./modules/fundsTransfer";
import rulesManager from "./modules/rulesManager";
import fraud from "./modules/fraud";
import fundsReversal from "./modules/fundsReversal";
import transaction from "./modules/transaction";
import walletTransactionLog from "./modules/walletTransactionLog";
import terminalRequestForm from "./modules/terminalRequestForm";
import inactiveAccounts from "./modules/inactiveAccounts";
import walletCrediting from "./modules/walletCrediting";
import generalTerminals from "./modules/generalTerminals";
import providusRelated from "./modules/providusRelated";
import unmapTerminals from "./modules/unmapTerminals";
import terminalRequestsNew from "./modules/terminalRequestsNew";
import pricingBand from "./modules/pricingBand";
import general from "./modules/general";
import KudaBulkTransfer from "./modules/KudaBulkTransfer";
import emailPush from "./modules/emailPush";
import businessLogs from "./modules/businessLogs";
import salesAgent from "./modules/salesAgent";
import waitlist from "./modules/waitlist";
import overrideCode from "./modules/overrideCode";
import staffReferralCode from "./modules/staffReferralCode";
import uploadSheets from "./modules/uploadSheets";
import visitationLog from "./modules/visitationLog";
import retryPortal from "./modules/retryPortal";
import tractionSupportForm from "./modules/tractionSupportForm";
import csr from "./modules/csr";
import pndAccounts from "./modules/pndAccounts";
import terminalDeployment from "./modules/terminalDeployment";
import admin from "./modules/admin";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  modules: {
    auth,
    admin,
    paymentLogs,
    dataSet,
    bankAccounts,
    fundsTransfer,
    terminals,
    batch,
    loans,
    notifications,
    verification,
    wallet,
    cashback,
    downloads,
    rulesManager,
    fraud,
    fundsReversal,
    transaction,
    walletTransactionLog,
    businessLogs,
    terminalRequestForm,
    inactiveAccounts,
    // terminalRequestsKeyAccountsView,
    generalTerminals,
    terminalRequestsNew,
    walletCrediting,
    generalTerminals,
    providusRelated,
    unmapTerminals,
    terminalRequestsNew,
    pricingBand,
    general,
    KudaBulkTransfer,
    emailPush,
    KudaBulkTransfer,
    salesAgent,
    waitlist,
    overrideCode,
    staffReferralCode,
    uploadSheets,
    visitationLog,
    retryPortal,
    tractionSupportForm,
    csr,
    terminalDeployment,
    pndAccounts
  },
  plugins: [],
});
