import axios from "axios";
import router from "./../router/index";
import { apiUrl } from "./../environment/environment";
import { GET_LOCAL_DB_DATA } from "../browser-db-config/localStorage";

const config = {
  baseURL: apiUrl,
};

const HTTP_CLIENT = new axios.create(config);

const authInterceptor = (config) => {
  return config;
};

const loggerInterceptor = (config) => {
  return config;
};

HTTP_CLIENT.interceptors.request.use(authInterceptor);
HTTP_CLIENT.interceptors.request.use(loggerInterceptor);

HTTP_CLIENT.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const user = GET_LOCAL_DB_DATA("traction-app-user-data");
    const business = GET_LOCAL_DB_DATA(
      "traction-app-current-user-business-data"
    );
    const errorResponse = error.response.data;

    if (errorResponse.code == "401" || !user) {
      localStorage.removeItem("traction-app-user-data");
      localStorage.removeItem("traction-app-current-user-business-data");
      router.push({ name: "Login" });
    } else if (error.response.status === 404) {
      console.log(error.response.status);
      console.log("False");
    } else if (error.response.status === 403) {
      router.push({ name: "NoPermission" });
    } else if (error.response.status === 500) {
      router.push({ name: "ErrorPermission" });
    } else {
    }
    return Promise.reject(errorResponse);
  }
);

export { HTTP_CLIENT };
