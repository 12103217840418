import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  loans: [],
});

const getters = {
  GET_LOANS: (state) => state.loans,
};

const mutations = {
  SET_LOANS: (state, data) => {
    state.loans = data;
  },
};

const actions = {
  FETCH_ALL_LOANS: async function ({ commit }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/getallloans`,
      // url: `/v1/payments/reports/paymentlogswithtime/businesses/${GET_USER_BUSINESS_ID()}?startdatetime=${payload.startdatetime}T${payload.startTime}:00.000&enddatetime=${payload.enddatetime}T${payload.endTime}:59.999`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_LOANS", httpRes);
    return httpRes;
  },
  CREATE_LOAN: async function (context, payload) {
    try {
      const httpReq = {
        // const httpReq = {
        url: `/v1/payments/admin/addloan`,
        method: "POST",
        authRequest: true,
        body: payload,
      };
      const httpRes = await HTTP_REQUEST(httpReq);
      return httpRes;
    } catch (error) {
      return error;
    }
  },
  GET_SINGLE_LOAN: async function (context, { id }) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/getloan/${id}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  //   GET_ALL_TERMINAL_PROVIDERS: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/stores/listterminalprovidernames`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  //   GET_ALL_BANKS: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/stores/listbanknames`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  //   GET_ALL_BUSINESSES: async function() {
  //     const httpReq = {
  //     // const httpReq = {
  //       url: `/v1/businesses/admin/getallbusinesses`,
  //       method: "GET",
  //       authRequest: true,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes

  //   },

  UPDATE_LOAN: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      url: `/v1/payments/admin/updateloan`,
      method: "PATCH",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
