const shouldBeString = (variable, label = null) =>
  typeof variable === "string" ||
  `${label || variable} should be string. Provided Value: ${variable}`;
const shouldBeArray = (variable) =>
  (Array.isArray(variable) && typeof variable === "object") ||
  `${variable} should be an array`;
const shouldBeObject = (variable, label = null) =>
  (!Array.isArray(variable) && typeof variable === "object") ||
  `${label || variable} should be an array`;
const shouldBeNumeric = (variable) =>
  !isNaN(variable) || `${variable} should be an numeric`;
const shouldBeBoolean = (variable) =>
  typeof variable === "boolean" || `${variable} should be boolean`;
const shouldBeFunction = (variable) =>
  typeof variable === "function" || `${variable} should be a function`;
const validateArguments = (rules = []) => {
  let valid = false;
  let errors = [];
  valid = rules.every((rule) => rule === true);
  errors = rules.filter((rule) => rule !== true);
  return [valid, errors.length > 0 ? errors : null];
};
const displayErrors = (arrayOfErrors = []) => {
  let messageString = "";
  arrayOfErrors.forEach((singleError) => {
    messageString += `${singleError} \n\r`;
  });
  return messageString;
};
const isAllowedToTrackErrors = () => {
  const allowedHosts = ["admin.tractionapps.co"];
  const host = window.location.hostname;
  return {
    isAllowed: allowedHosts.includes(host),
    hostname: host,
  };
};
import * as Sentry from "@sentry/vue";
export default {
  install(Vue, options = { isProduction, sentryDsn: "" }) {
    const checkIfAllowed = isAllowedToTrackErrors();
    if (options.isProduction && checkIfAllowed.isAllowed === true) {
      Sentry.init({
        Vue: Vue,
        dsn: options.sentryDsn,
      });
    } else {
      console.warn(
        `Sentry Plugin: Not allowed for ${checkIfAllowed.hostname}. Will not initiate`
      );
    }
    Vue.testGlobalMethod = function () {
      console.log("hello global method");
    };
    Vue.setupUserForSentryTracking = function (dataObject) {
      const [valid, errors] = validateArguments([
        shouldBeObject(dataObject, "dataObject"),
        shouldBeBoolean(options.isProduction, "options.isProduction"),
      ]);
      if (valid) {
        if (options.isProduction) {
          Sentry.configureScope((scope) => {
            Object.keys(dataObject).forEach((prop) => {
              scope.setTag(prop, dataObject[prop] || "N/A");
            });
          });
        }
      } else {
        throw new Error(
          `Vue.setupUserForSentryTracking: ` + displayErrors(errors)
        );
      }
    };
    Vue.sentryCaptureMessage = function (stringMessage) {
      const [valid, errors] = validateArguments([
        shouldBeString(stringMessage, "stringMessage"),
        shouldBeBoolean(options.isProduction, "options.isProduction"),
      ]);
      if (valid) {
        if (options.isProduction) {
          Sentry.captureMessage(stringMessage);
        }
      } else {
        throw new Error(`Vue.sentryCaptureMessage: ` + displayErrors(errors));
      }
    };
    Vue.sentryCaptureException = function (Event) {
      const [valid, errors] = validateArguments([
        shouldBeBoolean(options.isProduction, "options.isProduction"),
      ]);
      if (valid) {
        if (options.isProduction) {
          Sentry.captureException(Event);
        }
      } else {
        throw new Error(`Vue.sentryCaptureException: ` + displayErrors(errors));
      }
    };
  },
};
