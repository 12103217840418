import { v4 as uuidv4 } from 'uuid';
import { Device } from '@capacitor/device';
// import {eventBus} from '../eventBus'
import platform from 'platform';
import { ClientJS } from 'clientjs';
export default {
    data() {
        return {
            WLdeviceId: null,
            showOtpModal: false,
            otp: null,
            deviceList: [],
            showUnsanctionedDeviceModal: false,
            inValidOtp: false,
            clientDevice: new ClientJS()
        }
    },
    created () {
        if(!this.getDeviceId()) {
            this.WLdeviceId = this.setDeviceId();
        }
        this.getDeviceDetails();
        // console.log('is safari?', this.clientDevice.isSafari());
        console.log('clientJs: ', this.clientDevice.getFingerprint());
    },
    methods: {
        getDeviceId() {
            this.WLdeviceId = localStorage.getItem('WLdeviceId') || null
            return this.WLdeviceId;
        },
        setDeviceId() {
            const deviceId = this.clientDevice.getFingerprint()
            localStorage.setItem('WLdeviceId', deviceId);
            return deviceId;
        },
        actBasedOnResponseCode(response) {
            if(response.code === "400") {
                // eventBus.$emit('trac-alert', { message: response.message });
                return false;
            } else if(response.code === 'DEV001') {
                this.showOtpModal = true;
                return false;
            } else if(response.code === "200") {
                return true;
            }
        },
        isSanctioned(response = null) {
            if(response && response.error === 'Device not recognized - Logout and login again to add device') {
                this.showUnsanctionedDeviceModal = true;
                return false;
            }
        },
        async generateDeviceDetails(options = {email, otp}) {
            const deviceInfo = await this.getDeviceDetails()
            const payload = {
                email: options.email,
                code: options.otp,
                device: {
                    deviceId: this.WLdeviceId,
                    deviceModel: deviceInfo.model,
                    browser: {
                        name: platform.name,
                        version: deviceInfo.webViewVersion
                    },
                    whitelisted: true,
                    deviceType: 'browser'
                }
            }
            // console.log('payload', payload);
            return payload
        },
        async getDeviceDetails() {
            const info = await Device.getInfo();
            // const deviceId = await Device.getId();
            // const platformInfo = platform
          
            // console.log('device Details', info);
            // console.log('deviceId', deviceId);
            // console.log('platform', platformInfo);
            return info
        },    
    },
    computed: {
        otpIsValid() {
            return this.otp && this.otp.length === 4;
        },
        computerDevices() {
            return this.deviceList.filter(device => device.deviceType === 'browser');
        },
        mobileDevices() {
            return this.deviceList.filter(device => device.deviceType === 'Mobile');
        },
        terminalDevices() {
            return this.deviceList.filter(device => device.deviceType === 'Terminal');
        },
        computerListPlural() {
            return this.computerDevices.length > 1;
        },
        mobileListPlural() {
            return this.mobileDevices.length > 1;
        },
        terminalListPlural() {
            return this.mobileDevices.length > 1;
        },
        
    },
}
