import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = () => ({
  override_lists: [],
});

const getters = {
  GET_OVERRIDE_LISTS: (state) => state.override_lists,
};

const mutations = {
  SET_OVERRIDE_LISTS: (state, data) => {
    state.override_lists = data;
  },
};

const actions = {
  FETCH_OVERRIDE_LIST: async function({ commit }) {
    const httpReq = {
      url: `/v1/stores/admin/list_over_ride_code?limit=100&page=1`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_OVERRIDE_LISTS", httpRes);
    return httpRes;
  },

  CREATE_OVERRIDE_CODE: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/admin/terminaldeploymentcode`,
      method: "POST",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  PAGINATE_OVERRIDE_LIST: async function({ commit }, page) {
    const httpReq = {
      url: `/v1/stores/admin/list_over_ride_code?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_OVERRIDE_LISTS", httpRes);
    return httpRes;
  },
  DOWNLOAD_OVERRIDE_LIST: async function({ commit }, page) {
    const httpReq = {
      url: `/v1/stores/admin/list_over_ride_code?limit=100&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_OVERRIDE_LISTS", httpRes);
    return httpRes;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
