const { NODE_ENV = "" } = process.env;

const environment = NODE_ENV.toLowerCase();

const apiUrl = "https://traction-backend.tractionapps.co";

const kudaApiUrl = "https://tractionapp-fundtransfer.tractionapps.co";

// const apiUrl =
//   window.location.hostname === "admin.tractionapps.co"
//     ? "https://tractionapps.herokuapp.com"
//     : "https://tractionapp-stage.herokuapp.com";

const extApiUrl = "http://34.204.53.88:8084";

export { environment, apiUrl, extApiUrl, kudaApiUrl };
// export { environment, apiUrl };
