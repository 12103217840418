import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  all_documents: [],
  verified_documents: [],
};

const getters = {
  GET_ALL_VERIFICATIONS: (state) => state.all_documents,
  GET_VERIFIED_DOCUMENTS: (state) => state.verified_documents,
};

const mutations = {
  SET_DOCUMENTS: (state, data) => {
    state.all_documents = data;
  },
  SET_VERIFIED_DOCUMENTS: (state, data) => {
    state.verified_documents = data;
  },
};
const actions = {
  ALL_VERIFICATION_DOCUMENTS: async function ({ commit }) {
    const httpReq = {
      url: "/v1/kyc/kyc-info?verificationType=EMAIL,PHONE",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  ALL_VERIFICATION_DOWNLOAD_CSV: async function ({ commit }) {
    const httpReq = {
      url: "/v1/kyc/kyc-info?verificationType=EMAIL,PHONE&download=csv",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  FETCH_PAGINATED_ALL_VERIFICATION_DOCUMENTS: async function (
    { commit },
    page
  ) {
    const httpReq = {
      url: `/v1/kyc/kyc-info?verificationType=EMAIL,PHONE&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  FETCH_ALL_DOCUMENT_VERIFICATIONS: async function (
    { commit },
    {
      page,
      date,
      dateFilterActive,
      statusFilterActive,
      status,
      searchActive,
      search,
      documentFilterActive,
      filterByDocumentType,
    }
  ) {
    let urlPath = `/v1/kyc/kyc-info?verificationType=DOCUMENT&page=${page}`;

    urlPath += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : ``;

    urlPath +=
      statusFilterActive && status !== "ALL" ? `&status=${status}` : ``;

    urlPath += searchActive ? `&business_name=${search}` : ``;

    urlPath += documentFilterActive
      ? `&documentTypes=${filterByDocumentType}`
      : ``;

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },
  ALL_VERIFICATION_DOCUMENTS: async function (
    { commit },
    {
      page,
      dateFilterActive,
      date,
      contactFilterActive,
      filterByContactType,
      searchActive,
      search,
    }
  ) {
    let urlHandler = `/v1/kyc/kyc-info?page=${page}`;

    urlHandler += contactFilterActive
      ? `&verificationType=${filterByContactType}`
      : `&verificationType=EMAIL,PHONE`;
    urlHandler += searchActive ? `&business_name=${search}` : "";

    urlHandler += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : "";

    const httpReq = {
      url: urlHandler,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  FETCH_ALL_ADDRESS_VERIFICATIONS: async function (
    { commit },
    {
      page,
      date,
      dateFilterActive,
      statusFilterActive,
      status,
      searchActive,
      search,
    }
  ) {
    let urlPath = `/v1/kyc/kyc-info?verificationType=ADDRESS&page=${page}`;

    urlPath += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : ``;

    urlPath += statusFilterActive ? `&status=${status}` : ``;
    urlPath += searchActive ? `&business_name=${search}` : ``;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },
  ALL_VERIFICATION_DOWNLOAD_CSV: async function (
    { commit },
    {
      dateFilterActive,
      date,
      contactFilterActive,
      filterByContactType,
      searchActive,
      search,
    }
  ) {
    let urlHandler = `/v1/kyc/kyc-info?download=csv`;

    urlHandler += contactFilterActive
      ? `&verificationType=${filterByContactType}`
      : `&verificationType=EMAIL,PHONE`;
    urlHandler += searchActive ? `&business_name=${search}` : "";

    urlHandler += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : "";
    const httpReq = {
      url: urlHandler, //"/v1/kyc/kyc-info?verificationType=EMAIL,PHONE&download=csv",
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  VERIFICATION_DOWNLOAD_CSV: async function (
    { commit },
    {
      date,
      dateFilterActive,
      statusFilterActive,
      status,
      searchActive,
      search,
      documentFilterActive,
      filterByDocumentType,
      source,
    }
  ) {
    let urlPath = `/v1/kyc/kyc-info?verificationType=${source}&download=csv`;

    urlPath += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : ``;

    urlPath +=
      statusFilterActive && status !== "ALL" ? `&status=${status}` : ``;

    urlPath += searchActive ? `&business_name=${search}` : ``;

    urlPath += documentFilterActive
      ? `&documentTypes=${filterByDocumentType}`
      : ``;

    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    // commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_VERIFICATION_DOCUMENT: async function (context, { id }) {
    const httpReq = {
      url: `v1/kyc/${id}/kyc-info`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_ALL_CORPORATE_VERIFICATIONS: async function (
    { commit },
    {
      page,
      date,
      dateFilterActive,
      statusFilterActive,
      status,
      searchActive,
      search,
    }
  ) {
    let urlPath = `/v1/kyc/kyc-info?verificationType=CORPORATE&page=${page}`;

    urlPath += dateFilterActive
      ? `&start_date=${date.startDate}&end_date=${date.endDate}`
      : ``;

    urlPath += statusFilterActive ? `&status=${status}` : ``;
    urlPath += searchActive ? `&business_name=${search}` : ``;
    const httpReq = {
      url: urlPath,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },
  FETCH_SINGLE_VERIFICATION_DOCUMENT_NEW: async function (context, { id }) {
    const httpReq = {
      url: `v1/kyc/business/${id}/kyc-info`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  APPROVE_KYC: async function (context, payload) {
    const httpReq = {
      //https://tractionapp-stage.herokuapp.com/v1/kyc/verify/%7BkycId%7D/kyc-status
      // const httpReq = {
      url: `v1/kyc/verify/${payload.id}/kyc-status`,
      // url: `/v1/kyc/verify/${id}/status/approve`,
      method: "PUT",
      authRequest: true,
      body: { comment: payload.comment, status: payload.status },
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  DISAPPROVE_KYC: async function (context, payload) {
    const httpReq = {
      // const httpReq = {
      // /v1/kyc/verify/606c1f94925a560004638321/status/decline
      url: `v1/kyc/verify/${payload.id}/kyc-status`,
      method: "PUT",
      authRequest: true,
      body: { comment: payload.comment, status: payload.status },
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  OVERRIDE_KYC: async function (context, payload) {
    const httpReq = {
      url: `/v1/kyc/override?isexempt=true`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UNOVERRIDE_KYC: async function (context, payload) {
    const httpReq = {
      url: `/v1/kyc/override?isexempt=false`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  OVERRIDE_BVN: async function (context, payload) {
    const httpReq = {
      url: `v1/kyc/override?isexempt=true&kycType=bvn`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  UNOVERRIDE_BVN: async function (context, payload) {
    const httpReq = {
      url: `v1/kyc/override?isexempt=false&kycType=bvn`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  FETCH_PAGINATED_DOCUMENT_VERIFICATIONS: async function ({ commit }, page) {
    const httpReq = {
      // url: `/v1/kyc/verify/status/all`,
      url: `/v1/kyc/kyc-info?verificationType=DOCUMENT&page=${page}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },

  SEARCH_PAGINATED_DOCUMENT_VERIFICATIONS: async function ({ commit }, search) {
    const httpReq = {
      // url: `/v1/kyc/kyc-info?verificationType=DOCUMENT&=${search}`,
      url: `/v1/kyc/business?business_name=${search}&verification_types=DOCUMENT`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_VERIFIED_DOCUMENTS", httpRes);
    return httpRes;
  },

  SEARCH_PAGINATED_VERIFICATIONS: async function ({ commit }, search) {
    const httpReq = {
      // url: `/v1/kyc/kyc-info?verificationType=DOCUMENT&=${search}`,
      url: `/v1/kyc/business?business_name=${search}&verification_types=EMAIL,PHONE`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    commit("SET_DOCUMENTS", httpRes);
    return httpRes;
  },
  BLACKLIST_ID: async function (context, payload) {
    const httpReq = {
      url: `/v1/kyc/blacklist/${payload.id}`,
      method: "POST",
      authRequest: true,
      body: {
        idType: payload.idType,
        idNumber: payload.idNumber,
        note: payload.note,
      },
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
