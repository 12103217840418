import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  inactiveAccounts: [],
};

const getters = {
  // GET_INACTIVE_ACCOUNTS: (state) => state.inactiveAccounts,
};

const mutations = {
  // SET_INACTIVE_ACCOUNTS_RES: (state, data) => {
  //   state.inactiveAccounts = data;
  // },
};

const actions = {
  FETCH_PND_ACCOUNTS: async function(context, criteria) {
    const acc = []
    const keys = Object.keys(criteria)
    keys.forEach((key) => {
      acc.push(`${key}=${criteria[key]}`)
    })

    const combined = acc.join("&")
    const querystring = `${keys.length > 0 ? '?'+combined : ''}`
    console.log('querystring:', querystring)
    // debugger
    const httpReq = {
      url: `/api/wallets${querystring}`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  PND_ACCOUNT: async function(context, {uid, status,}) {
    const httpReq = {
      url: `/api/wallet/update/status/${uid}`,
      method: "PATCH",
      authRequest: true,
      body: {
        status
      }
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};